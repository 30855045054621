import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { getSearchSuggestions,GetDeals } from '../Actions/WebsiteActions';
import { Link } from 'react-router-dom';
import AccountNavigation from './Common/AccountNavigation';


const mapStateToProps = (state) => {
	return {
		searchResultsLocations: state.Website.searchResultsLocations,
		deals: state.Website.deals
	}
}

const mapDispatchToProps = dispatch => ({
	getSearchSuggestions: (keyword) => dispatch(getSearchSuggestions(keyword)),
	GetDeals:()=>dispatch(GetDeals())
});
class MyDeals extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
		}

		this.props.GetDeals();
	}


	handleChange = (event, field) => {

		this.setState({
			[field]: event.target.value
		}, () => {

			this.props.getSearchSuggestions(this.state.searchField);

		});
	}


	render() {
		return <Fragment>
			<Header  />
			<section class="body-gry setting-pg">
	<div class="container">
		<div class="row">
			<div class="col l4 m5 s12">
				<AccountNavigation/>
			</div>

			<div class="col l8 m7 s12">
				<div class="set-cont">
					<div class="hed-bed">
						<div class="bred-cmp">
							<a href="">Dashboard</a><span>Deals & Discount</span>
						</div>
						<h1>Deals & Discount</h1>
					</div>
					<div class="set-act-div">
						<div class="deal">
							{/* <div class="co-4">
								<div class="sub-div">
									<img src="/img/asia.png" />
									<span class="off">
										<p>40%</p>
									</span>
									<div>
										<h4>Asia</h4>
										<p>1671 deals</p>
									</div>
								</div>
							</div> */}
							{/* <div class="co-6">
								<div class="sub-div">
									<img src="/img/éurope.png" />
									<span class="off">
										<p>40%</p>
									</span>
									<div>
										<h4>Europe</h4>
										<p>1671 deals</p>
									</div>
								</div>
							</div> */}
							{/* <div class="co-10">
								<div class="sub-div">
									<img src="/img/nuwara.png" />
									<span class="off sm">
										<p>40%</p>
									</span>
									<div>
										<h4>Nuwara Eliya</h4>
										<p>1671 deals</p>
									</div>
								</div>
								
							</div> */}

							{ this.props.deals.map((item)=>{
								return <div class="co-5">
								<div class="sub-div">
									<img src={item.image} />
									<span class="off sm">
										<p>{item.discount}%</p>
									</span>
									<div>
										<h4>{item.title}</h4>
										<p>{item.num_activities} deals</p>
									</div>
								</div>
							</div>;
							})}

							
							
							<div class="clear"></div>
						</div>
								
						
						
					</div>

				</div>
			</div>
		</div>
	</div>
	
</section>
								<Footer />

                </Fragment>
							}
						}
						
export default compose(connect(mapStateToProps, mapDispatchToProps))(MyDeals);