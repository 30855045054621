import React from 'react'
import {BrowserRouter, Route, Switch,Redirect} from 'react-router-dom'

import Home from '../Pages/Home';
import SupplierSignUp from '../Pages/SupplierSignUp';
import SearchResult from '../Pages/SearchResult';
import ActivityDetails from '../Pages/ActivityDetails';
import Login from '../Pages/Login';

import MyAccount from '../Pages/MyAccount';
import MyAccountBookings from '../Pages/MyAccountBookings';
import MyReviews from '../Pages/MyReviews';
import MyDeals from '../Pages/MyDeals';
import MySettings from '../Pages/MySettings';
import AccountSignup from '../Pages/ClientSignUp';
import BookingDetail from '../Pages/MyBookingDetail';
import ShowCart from '../Pages/ShowCart';
import ShowCheckout from '../Pages/ShowCheckout';
import SupplierSignUpComplete from '../Pages/SupplierSignUpComplete';
import MyFavorites from '../Pages/MyFavorites';
import About from '../Pages/About';
import Help from '../Pages/Help';
import PaymentComplete from '../Pages/PaymentComplete'
import LocationPage from '../Pages/LocationPage'



export default ()=>( 
    <BrowserRouter basename="/"> 
    <div>    
        <Route path="/" exact component={Home}  />
        <Route path="/signup" exact component={SupplierSignUp}  />
        <Route path="/signup/complete" exact component={SupplierSignUpComplete}  />
        <Route path="/search" exact component={SearchResult}  />
        <Route path="/activity/details/:aid" component={ActivityDetails}  />
        <Route path="/activity/:seourl/details/:aid" component={ActivityDetails}  />
        <Route path="/login" component={Login}  />

        <Route path="/account" exact component={MyAccount}  />    
        <Route path="/account/bookings" exact component={MyAccountBookings}  />
        <Route path="/account/booking/detail/:bid" exact component={BookingDetail}  />        
        <Route path="/account/reviews" exact component={MyReviews}  />
        <Route path="/account/deals" exact component={MyDeals}  />
        <Route path="/account/settings" exact component={MySettings}  />
        <Route path="/account/signup" exact component={AccountSignup}  />
        
        <Route path="/account/favorites" exact component={MyFavorites}  />

        <Route path="/cart" exact component={ShowCart}  />
        <Route path="/checkout" exact component={ShowCheckout}  />

        <Route path="/about" exact component={About}  />
        <Route path="/help" exact component={Help}  />
        <Route path="/help/:sid" exact component={Help}  />
        
        <Route path="/payment/complete" exact component={PaymentComplete}  />

        <Route path="/:loaction/:title/location/:pid" exact component={LocationPage}  />
        
    </div>
     </BrowserRouter>
);

