export default function reducer(state = {
    searchResultsLocations: [],
    searchResultsActivities: [],
    mybookings: {
        current_page: null,
        data: [],
        first_page_url: null,
        from: null,
        last_page: null,
        last_page_url: null,
        next_page_url: null,
        path: null,
        per_page: 12,
        prev_page_url: null,
        to: null,
        total: null,
    },

    activeBooking: {

        activity: null,
        activity_id: null,
        booking_reference: null,
        contact_email: null,
        contact_firstname: null,
        contact_lastname: null,
        contact_phone: null,
        created_at: null,
        deleted_at: null,
        end_date: null,
        hotel_address: null,
        hotel_name: null,
        id: 0,
        num_adults: 0,
        num_children: 0,
        num_infants: 0,
        payment_amount: null,
        payment_reference: null,
        pickup_location: null,
        remarks: null,
        start_date: null,
        status: null,
        updated_at: null,
        user_id: null,
        travellers: [],
        pickup: null,
    },
    myreviews: [],
    BookingMessages: [],
    supplierSignUpComplete: false,
    clientSignUpComplete: false,
    featuredTours: [],
    topSellingTours: [],
    dealsDiscountedTours: [],
    sliders: [],
    featuredCategories: [],
    site_title: "",
    site_description: "",
    site_keywords: "",
    site_featured: 0,
    site_aboutus: "",
    site_privacy: "",
    site_cookie: "",
    site_disclaimer: "",
    site_guidelines: "",
    deals: [],
    suggestedLocations: [],
    instagram: null,
    activePage: {
        id: 0,
        location: "",
        title: "",
        description: "",
        seo_title: "",
        seo_description: "",
        seo_url: "",
        full_seo_url: "",
        image_alt: "",
        activities: [],
        image_name: null,
        location_data:null,
        updated: false,
    }

}, action) {
    switch (action.type) {
        case "STORE_SEARCH_SUGGESTIONS": {
            return { ...state, searchResultsLocations: action.locations, searchResultsActivities: action.activities }
        }
        case "STORE_MY_BOOKINGS": {
            return { ...state, mybookings: action.list }
        }
        case "STORE_ACTIVE_BOOKING": {
            return { ...state, activeBooking: action.booking }
        }
        case "STORE_REVIEWS": {
            return { ...state, myreviews: action.reviews }
        }
        case "STORE_ACTIVE_BOOKING_MESSAGES": {
            return { ...state, BookingMessages: action.messages }
        }
        case "SIGNUP_COMPLETE": {
            return { ...state, [action.field]: true }
        }
        case "STORE_FEATURED": {
            return { ...state, featuredTours: action.list }
        }
        case "STORE_TOP_SELLING": {
            return { ...state, topSellingTours: action.list }
        }
        case "STORE_DEALS": {
            return { ...state, dealsDiscountedTours: action.list }
        }
        case "STORE_SLIDERS": {
            return { ...state, sliders: action.slides }
        }
        case "STORE_FEATURED_CATS": {
            return { ...state, featuredCategories: action.cats }
        }
        case "STORE_SITE_SETTINGS": {
            return {
                ...state, site_title: action.settings.title,
                site_description: action.settings.description,
                site_keywords: action.settings.keywords,
                site_featured: action.settings.featured
            }
        }
        case "STORE_SITE_CONTENT": {
            return {
                ...state, site_aboutus: action.settings.aboutus,
                site_privacy: action.settings.privacy,
                site_cookie: action.settings.cookie,
                site_disclaimer: action.settings.disclaimer,
                site_guidelines: action.settings.guidelines
            }
        }
        case "FEATURED_SEARCH_ITEMS": {
            return {
                ...state, featuredSearchedItems: action.settings.aboutus,
                site_privacy: action.settings.privacy,
                site_cookie: action.settings.cookie,
                site_disclaimer: action.settings.disclaimer,
                site_guidelines: action.settings.guidelines
            }
        }
        case "STORE_FEATURED_DEALS": {
            return { ...state, deals: action.list }
        }
        case "STORE_INSTAGRAM_FEED": {
            return { ...state, instagram: action.data }
        }
        case "STORE_SUGGESTED_LOCATIONS": {
            return { ...state, suggestedLocations: action.list }
        }
        case "STORE_LOCATION_CONTENT": {
            return {
                ...state, 
                activePage: {
                    ...state.activeMessage,                    
                    id: action.p.id,
                    location: action.p.location,
                    title: action.p.title,
                    description: action.p.description,
                    seo_title: action.p.seo_title,
                    seo_description: action.p.seo_description,
                    seo_url: action.p.seo_url,
                    image_alt: action.p.image_alt,
                    activities: action.p.activities,
                    image_name: action.p.image_name,
                    full_seo_url: action.p.full_seo_url,
                    location_data:action.p.location_data,
                    updated: true
                }
            }
        }

    }

    return state
}