import React, { Props, Component, Fragment } from 'react';

import SearchResultLIstViewItem from './SearchResultLIstViewItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import { compose } from 'redux';
import { connect } from 'react-redux';


const paginationItem = (reslts,callback)=>{
	var items = [];
	for(var x=1; x <= reslts.last_page; x++){
		items.push(<li className={reslts.current_page === x ? 'active':'waves-effect' }><a onClick={callback.bind(this,x)} style={{cursor:'pointer'}}>{x}</a></li>)
	}

	return items;
}

const mapStateToProps = (state) => {
    return {
		searchResults:state.Activities.searchResults,
		normalSearchedItemsLoading:state.Common.normalSearchedItemsLoading		
    }
  }
  
  const mapDispatchToProps = dispatch => ({		
		
  });
class GridView extends Component {

    constructor(props){
		super(props)
		
		console.log('hello');
	}
	
	componentWillReceiveProps(){
		console.log('Here');
	}
	

    render() {

		

        return (
            <div>
                <div className="row">
					<div className="col l12">
					{ this.props.normalSearchedItemsLoading === true ? (<div style={{width:'100%',minHeight:200,textAlign:'center'}} ><CircularProgress color="#000" /><br/>Loading...</div>):null}
					{ this.props.normalSearchedItemsLoading === false && this.props.searchResults === null ? (<div style={{width:'100%',minHeight:200,textAlign:'center',paddingTop:100}} >Sorry, no experiences matching your search</div>):null}
					{ this.props.searchResults !== null && this.props.searchResults.data.length === 0  ? (<div style={{width:'100%',minHeight:200,textAlign:'center',paddingTop:100}} >Sorry, no experiences matching your search</div>):null}
					
						{this.props.searchResults !== null ? this.props.searchResults.data.map((item,index)=>{
							return <SearchResultLIstViewItem searchParams={this.props.searchParams} data={item} key={'lv-'+index} />;
						}):null}
												
					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<div className="pagi">
						{this.props.searchResults !== null && this.props.searchResults.last_page > 1 ? (
							<ul className="pagination">								
								{paginationItem(this.props.searchResults,this.props.onPageChange)}								
							</ul>
						):null}
							
						</div>
					</div>
				</div> 
            </div>
        );
    }
}




export default compose(connect(mapStateToProps, mapDispatchToProps))(GridView);