import React, { Props,Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';


import {Redirect} from 'react-router-dom'
import { GetSiteContent } from '../Actions/WebsiteActions';
import Alert from './Common/Alert';

import Typography from '@material-ui/core/Typography';
const queryString = require('query-string');


function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`scrollable-auto-tabpanel-${index}`}
		aria-labelledby={`scrollable-auto-tab-${index}`}
		{...other}
	  >
		
		<div className="act">
		<h4><span class="getting"></span>{props.title}</h4>
										<div>
										<p>{props.content}</p>
									</div>
		</div>
		
	  </Typography>
	);
  }
  
  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
  };

const mapStateToProps = (state) => {
    return {
		loading : state.Common.loading,
		loggedIn : state.Auth.loggedIn,
		aboutus : state.Website.site_aboutus,
		site_privacy:state.Website.site_privacy,
		site_cookie: state.Website.site_cookie,
		site_disclaimer: state.Website.site_disclaimer, 
    }
  }
  
  const mapDispatchToProps = dispatch => ({		
	GetSiteContent:()=>dispatch(GetSiteContent())
  });
class About extends Component{

	constructor(props){
		super(props);

		const { sid } = this.props.match.params;

		this.state = {
            username: "",
            password:"",
            loggedIn:false,
			loading:false,
			tab:sid !== undefined && sid !== null ? parseInt(sid):0
		};
		
		this.props.GetSiteContent();
	}

	componentDidMount() {
        window.scrollTo(0,0)
	  }
	  
	handleChange = (selTab) => {
        this.setState({
			tab: selTab,
        });
	  };
	  

	  
	
	componentDidMount() {	

        window.scrollTo(0,0)
      }


    render(){
        return <Fragment>
                    <Header home={false}/>
					<Alert/>
                    <section className="bedcmp-section">
	<div className="container">
		<div className="row">
			<div className="col l12 m12 s12">
				<div className="hed-bed">
					<div className="bred-cmp">
						<a href="">Home</a> / <span>Help Center</span>
					</div>
					<h1>Help Center</h1>
					{/* <p>Nam liber tempor cum soluta nobis eleifend option</p> */}
				</div>
				<div className="hel-link">
					{/* <a className="getting" href="#getting">Getting Started</a> */}
					<a className="privacy" onClick={()=>this.handleChange(0)} >Privacy Policy</a>
					<a className="disclaimers" onClick={()=>this.handleChange(1)}>Disclaimers</a>
					{/* <a className="terms" >Terms & Conditions</a> */}
					<a className="cookies" onClick={()=>this.handleChange(2)}>Cookies</a>
				</div>
			</div>
		</div>
	</div>
</section>
<section>
	<div class="bot-shd">
		<div class="container">
			<div class="row">
				<div class="col l12">
					<div style={{paddingTop:50, paddingLeft:10, paddingRight:10}}>

						<TabPanel value={this.state.tab} index={0} title="Privacy Policy" content={this.props.site_privacy} />
						<TabPanel value={this.state.tab} index={1} title="Disclaimers" content={this.props.site_disclaimer} />
						<TabPanel value={this.state.tab} index={2} title="Cookies"content={this.props.site_cookie} />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section className="abou-gry" style={{marginTop:0}}>
	<div className="container">
		<div className="row">
			<div className="col l7">
				<div className="row">
					<div className="col l6 s12">
						<div className="cont-abou">
							<h5>Connect with Us!</h5>
							<p>Want to know more? Join us </p>
						</div>
					</div>
					<div className="col l6 s12">
						<div className="cont-abou">
							<ul>
							<li><a className="fb" href="https://www.facebook.com/aktivitar" target="_blank"></a></li>
							<li><a className="you" ></a></li>
							<li><a className="pin" href="https://www.instagram.com/aktivitar/" target="_blank"></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<Footer/>

                </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(About);