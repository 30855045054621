import React, { Props, Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import Inquary  from '../Common/InquiryPopup';
import {showInquire} from '../../Actions/CommonActions';
import { compose } from 'redux';
import { connect } from 'react-redux';



const mapStateToProps = (state) => {
    return {       
		show: state.Common.show_inquare,           
    }
}
const mapDispatchToProps = dispatch => ({
	showInquire:(data)=>dispatch(showInquire(data))
  });
  

class Footer extends Component {

	constructor(props){
        super(props);

        this.state = {
		  
			show_inquare:false,
		};
		// this.props.showInquare();
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "/js/app.min.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();
      
        document.body.appendChild(script);
      }

      scriptLoaded() {
        //window.A.sort();
      }

    render() {
        return (
            <footer>
	<div className="gar-fot">
		<div className="container">
			<div className="row">
				<div className="col l4 s12">
					<div className="fot-cont">
						<a href="">
							<img src="/img/logo.png" />
						</a>
						<p>
							Aktivitar is a registered trademark of <br />
							AKTIVITAR (PVT) LTD.<br />
							Use of this website constitutes acceptance 
							of Aktivitar's Terms & Conditions.
							{/* <Inquary/> */}
						</p>
					</div>
					
				</div>
				<div className="col l4 m6 s12">
					<div className="fot-menu">
						<div>
							<h4>Help center</h4>
							<ul>
								
								<li><a href="/help">Customer care</a></li>
								<li><a href="/help/0">Privacy policy</a></li>
								<li><a href="/help/2">Cookie policy</a></li>
								<li><a href="/help/1">Product desclaimers</a></li>								
								{/* <li><a href="">Terms & Conditions</a></li> */}
							</ul>
						</div>
						<div>
							<h4>Sitemap</h4>
							<ul>
								<li><Link to="/about">About us</Link></li>
								
								<li> <a style={{display:'inline'}} href="/signup"> Supplier : Sign-Up or</a><a  style={{display:'inline'}} href="https://supplier.aktivitar.com/"> Login</a>   </li>
								 
								{/* <li><a href="">Day Tours </a></li> */}
								{/* <li><a href="">Things to Do </a></li> */}
								
							</ul>
						</div>
					</div>
				</div>
				<div className="col l4 m6 s12">
					<div className="for-cont">
						<div className="yelo">
							<span>Call For Inquiries</span>
							<a className="call" href="tel:+94 112 579 579">+94 705 455 455</a>
							<div onClick={()=>{this.props.showInquire(true)}} >  Inquire Now   </div>
						</div>
						<span>Get the latest Updates</span>
						<ul>
							<li><a className="fb" href="https://www.facebook.com/aktivitar" target="_blank"></a></li>
							<li><a className="you" ></a></li>
							<li><a className="pin" href="https://www.instagram.com/aktivitar/" target="_blank"></a></li>
							
						</ul>
						<Inquary/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="fot-bot">
		<div className="container">
			<div className="row">
				<div className="col s12"> 
					<div className="copy">
						<p>Copyright 2018 Aktivitar. All Rights Reserved. <a target="_blank" href="https://www.extremewebdesigners.com/services/web-design-sri-lanka/">Design</a> by<a target="_blank" href="http://extremewebdesigners.com/"><img src="https://extremewebdesigners.com/images/extreme-web-design-sri-lanka.png" /> EWD</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
        );
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Footer);
