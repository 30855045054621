import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  

  const inputLabel = React.useRef(null);
  
  

  

  return (  
        <Select
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          displayEmpty
          className={classes.selectEmpty}
          style={props.style}
        >
          <MenuItem value={""} >
            {props.placeholder}
          </MenuItem>           

         { (props.options !== undefined || props.options !== null) ? props.options.map((item,i)=>{
             return <MenuItem key={props.name+'-'+i} value={item[props.optionvalue]}>{item[props.optionlabel]}</MenuItem>;
         }):null}    
          
          
        </Select>
        );
}