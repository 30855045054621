import React, { Props, Component, Fragment } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Divider, Chip } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { MyBooking,AddMessage,GetMessages,CancelBooking } from '../Actions/WebsiteActions';
import { Link } from 'react-router-dom';
import AccountNavigation from './Common/AccountNavigation';

const getStatus = (status) => {
	switch (status) {
	  case 0: { return "Pending" }
	  case 1: { return "Confirmed" }
	  case 2: { return "Rejected" }
	  case 3: { return "Canceled" }
	  case 4: { return "Completed" }
	  case 5: { return "Dispute" }
	}
  }
  
  const StatusStyle = (status) => {
	switch (status) {
	  case 0: { return { backgroundColor: '#90A4AE', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
	  case 1: { return { backgroundColor: '#4CAF50', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
	  case 2: { return { backgroundColor: '#673AB7', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
	  case 3: { return { backgroundColor: '#263238', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
	  case 4: { return { backgroundColor: '#00695C', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
	  case 5: { return { backgroundColor: '#f44336', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
	}
  }

const StatusChip = (status) => {
	return <Fragment><Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip></Fragment>
  }

const mapStateToProps = (state) => {
	return {
		activeBooking: state.Website.activeBooking,
		BookingMessages:state.Website.BookingMessages
	}
}



const mapDispatchToProps = dispatch => ({
	MyBooking: (bid) => dispatch(MyBooking(bid)),
	AddMessage:(bid,message)=>dispatch(AddMessage(bid,message)),
	GetMessages:(bid)=>dispatch(GetMessages(bid)),
	CancelBooking:(id)=>dispatch(CancelBooking(id))
});
class MyAccountBookings extends Component {

	taRef = React.createRef();
  	chatBoxRef = React.createRef();

	constructor(props) {
		super(props);

		var {bid} = this.props.match.params;

		this.state = {
			message: "",
			visible:'supplier'
		}


		if(bid !== undefined){
			this.props.MyBooking(bid);
			this.props.GetMessages(bid);		
		}

		
	}

	auto_grow = (element) => {
		this.taRef.current.style.height = "5px";
		this.taRef.current.style.height = (this.taRef.current.scrollHeight)+"px";
	 }

	 componentDidMount=()=>{
		this.auto_grow();
	  }

	componentDidUpdate = () =>{
		this.chatBoxRef.current.scrollTop = this.chatBoxRef.current.scrollHeight;
	  }

	getTripType(type){
        
		switch(type){
		  case 1 :{ return "Round tour" }
		  case 2 :{ return "Day Tour" }
		  case 3 :{ return "Activity" }
		}
	}
	
	getDuration(measure){        
	  switch(measure){
		case 1 :{ return "Days" }
		case 2 :{ return "Hours" }
		case 3 :{ return "Minutes" }
	  }
	}
	
	
	getStatus(status) {
		switch (status) {
		  case 0: { return "Pending" }
		  case 1: { return "Confirmed" }
		  case 2: { return "Rejected" }
		  case 3: { return "Canceled" }
		  case 4: { return "Completed" }
		  case 5: { return "Dispute" }
		}
	  }
	getPricingBasis(status){ 
	  console.log(status);       
	  switch(status){    
		case '1':{ return "Per Person" }
		case '2' :{ return "Per Group" }
	  }
	}

	
	handleCancelBooking = () =>{
		this.props.CancelBooking(this.props.activeBooking.id);
	}

	handleChange = (event, field) => {

		this.setState({
			[field]: event.target.value
		},()=>this.auto_grow());
	}


	sendMessage = ()=>{
		var message = this.state.message;

		this.setState({
			message:""
		})
		this.props.AddMessage(this.props.activeBooking.id,{message:this.state.message,visible:this.state.visible});	
	}

	render() {
		return <Fragment>
			<Header />

			<section className="body-gry setting-pg">
	<div className="container">
		<div className="row">
			<div className="col l4 m5 s12">
				<AccountNavigation/>
			</div>

			<div className="col l8 m7 s12">
				<div className="set-cont">
					
					<div className="bk-deit">
						<div className="bk-img">

						{this.props.activeBooking.activity !== null ? (
											<img src={this.props.activeBooking.activity.activity_images[0].name} />
						):null}						
							
						</div>
						<div className="bk-cont">
							<div className="bk-hed">
								<h4>{this.props.activeBooking.activity !== null ? this.props.activeBooking.activity.title:null} </h4>
							</div>
							<div className="bk-typs">
								<p className="ty">Tour type <span> {this.props.activeBooking.activity !== null ? this.getTripType(this.props.activeBooking.activity.tour_type):null}</span></p>
								<p className="pc">Product Code <span> {this.props.activeBooking.activity !== null ? this.props.activeBooking.activity.tour_id:null}</span></p>
							</div>
							<div className="bk-tb">
								<div className="duration">
									<span>Duration</span>
									{this.props.activeBooking.activity !== null ? this.props.activeBooking.activity.duration_value:null} {this.props.activeBooking.activity !== null ? this.getDuration(this.props.activeBooking.activity.duration):null}
								</div>
								<div className="pax">
									<span>No of pax</span>
									{this.props.activeBooking.num_adults}A/{this.props.activeBooking.num_children}C/{this.props.activeBooking.num_infants}I
								</div>
								<div className="supplier">
									<span>Supplier and Contact </span>
									{this.props.activeBooking.activity !== null ? this.props.activeBooking.activity.user.supplier_profile.work_name:null} - {this.props.activeBooking.activity !== null ? this.props.activeBooking.activity.user.supplier_profile.work_mobile:null}
								</div>
								<div className="location">
									<span>Location </span>
									{this.props.activeBooking.pickup !== null ? this.props.activeBooking.pickup.location_name : null}
								</div>
								<div className="rate">
									<span>Rate </span>
									{this.props.activeBooking.booking_amount} USD
								</div>
							</div>
						</div>
						<div  className="clear"></div>
					</div>
					<div className="btn-rw">
						{/* <a className="waves-effect waves-light btn" href="">View Tour</a> */}
						{/* <a className="waves-effect waves-light btn" href="">Amend Tour</a> */}
						{ this.props.activeBooking.status === 1 || this.props.activeBooking.status === 0  ? (<a className="waves-effect waves-light btn" style={{cursor:'pointer'}} onClick={()=>this.handleCancelBooking()}>Cancel Tour</a>): null}
						{/* <a className="waves-effect waves-light btn" href="">Pay Now</a> */}
					</div> 
					<div className="row">
						<div className="col l5">
							<div className="bd">
								<h4>Booking Details</h4>
								<div>
									<p>Status<span>  {StatusChip(this.props.activeBooking.status)}   </span></p>
									<p>Lead Traveler<span>  {this.props.activeBooking.travellers[0] !== undefined ? this.props.activeBooking.travellers[0].firstname : null} {this.props.activeBooking.travellers[0] !== undefined ? this.props.activeBooking.travellers[0].lastname : null} </span></p>
									<p>Email<span>{this.props.activeBooking.contact_email}</span></p>
									<p>Contact Number<span> {this.props.activeBooking.travellers[0] !== undefined ? this.props.activeBooking.travellers[0].phone : null}   </span></p>
									
									<p>Booking Reference<span>{this.props.activeBooking.booking_reference}</span></p>
									<p>Booking timestamp <span>{this.props.activeBooking.created_at}</span></p>
									<p>Tour Date <span>{this.props.activeBooking.start_date}</span></p>
									<p>Pickup Place<span>{this.props.activeBooking.hotel_address}</span></p>
									<p>Pickup Time<span>{this.props.activeBooking.pickup !== null ? this.props.activeBooking.pickup.pickup_time : null}</span></p>
									<p>Remarks</p>
									<div>{this.props.activeBooking.remarks}</div>
								</div>
							</div>
						</div>
						<div className="col l7">
							<div className="ct-div">
								<h4>Messaging History</h4>
								<div className="chat-box" >
									<a ></a>
									<div className="cha-div" ref={this.chatBoxRef}>

									{this.props.BookingMessages.map((item)=>{
										return <div className={"cht "+(item.user.user_role === "supplier" ? 'rit':null)}>
													<div>
														{item.hidden === 1 ? "*Removed by admin" : item.message} 
													<span>{item.created_at}</span>
													</div>
												</div>;
									})}
									
									
									</div>
									
									
									
									<div className="frm" style={{marginTop:25}}>
										<p>Send Message to <RadioGroup aria-label="position" name="position" value={this.state.visible} onChange={(e)=>this.handleChange(e,'visible')} row>
								<FormControlLabel
								
								value="supplier"
								control={<Radio className="with-gap" style={{color:"#f7a414" }} color="primary" />}
								label="Supplier"
								labelPlacement="end"
								/>		
								<FormControlLabel
								className="with-gap"
								value="admin"
								control={<Radio className="with-gap" style={{color:"#f7a414" }} color="primary" />}
								label="Admin"
								labelPlacement="end"
								/>
							</RadioGroup></p>
										<form>
											<textarea ref={this.taRef}  placeholder="Type your message" value={this.state.message} onChange={(e)=>{this.handleChange(e,'message')}}></textarea>
											<input type="button" value="" onClick={()=>{ this.sendMessage(); }} />
										</form>
									</div>
									<div className="clear"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyAccountBookings);