import { combineReducers } from 'redux';

import Auth from './AuthReducer';
import Common from './CommonReducer';
import Categories from './Category';
import Activities from './Activities';
import Account from './Account';
import Website from './Website';
import Cart from './Cart';
import Locations from './Locations';
import Favorites from './Favorites';

export default combineReducers({    
    Auth,
    Common,
    Categories,
    Activities,
    Account,
    Website,
    Cart,
    Locations,
    Favorites
   })