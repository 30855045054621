import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { GetReviews } from '../Actions/WebsiteActions';
import { Link } from 'react-router-dom';
import AccountNavigation from './Common/AccountNavigation';

import StarRatingComponent from 'react-star-rating-component';

import Modal from '@material-ui/core/Modal';

import ReviewModal from './Common/ReviewModal';

const mapStateToProps = (state) => {
	return {
		myreviews:state.Website.myreviews
	}
}

const mapDispatchToProps = dispatch => ({
	GetReviews: () => dispatch(GetReviews())
});
class MyReviews extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
			activity:null,
			openModal:false,
		}

		this.props.GetReviews();
	}


	closeModal = () => {
		this.setState({ openModal:false});
	}

	
	onPostReview = (data) =>{
		
	}


	render() {
		return <Fragment>
			<Header  />
			<section className="body-gry setting-pg">
	<div className="container">
		<div className="row">
			<div className="col l4 m5 s12">
			<AccountNavigation/>
			</div>

			<div className="col l8 m7 s12">
				<div className="set-cont">
					<div className="hed-bed">
						<div className="bred-cmp">
							<a href="">Home</a> / <span>Reviews & Ratings</span>
						</div>
						<h1>Reviews & Ratings</h1>
					</div>
					<div className="upcomeing reviews-tbl">
						<table className="upcomeing-tbl">

						{ this.props.myreviews.map((item,index)=>{
								return <tr className="back-wit">
								<td className="pd-l">
									<h6 className="ae">{item.activity.title}</h6>
									<p className="av">{item.activity.overview.substr(0,55)}...</p>
								</td>
								<td className="tc">
									<p className="sp">{item.activity.user.supplier_profile.work_name}</p>
								</td>
								<td className="tc">
									<div className="ret">
									<StarRatingComponent
											name="rate2"
											editing={false}
											renderStarIcon={() => <span></span>}
											starCount={5}
											value={item.reviews !== null ? item.reviews.rating : 0}
											renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}
											/>
									</div>
								</td>
								<td className="tc"><a onClick={()=>{this.setState({openModal:true, activity:item})}} className="waves-effect waves-light btn modal-trigger">View</a></td>
							</tr>;
							})}
							
							
							
							
						</table>
					</div>
					
				</div>
			</div>
		</div>
	</div>
 
  
	  <ReviewModal onClose={this.closeModal} data={this.state.activity} open={this.state.openModal} />
  
</section>
								<Footer />

                </Fragment>
							}
						}
						
export default compose(connect(mapStateToProps, mapDispatchToProps))(MyReviews);