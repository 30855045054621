import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError,ConfimEmail,ShowLoadingSpinner} from './CommonActions';
import store from '../store';
import axios from 'axios';


export function registerAccount(data,UserType) {
    console.log(data);
    return function(dispatch) {
        dispatch(SnackBar(true,'Processing registration, please wait...','info'));
        fetch(API_URL+'/api/register',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            },
            body:JSON.stringify({
                'client_id':API_CLIENT_ID,
                'client_secret':API_CLIENT_SECRET,
                'user_type':UserType,
                'first_name':data.first_name,
                'last_name':data.last_name,
                'email':data.email,
                'password':data.password,
                'confim_password':data.confim_password                
                
            }),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                  
                  
                  if(UserType === 2){
                    dispatch(SignUpComplete('supplierSignUpComplete'));
                  }else{
                    dispatch(SignUpComplete('clientSignUpComplete'));
                  }
                  dispatch(SnackBar(true,'Registration complete','success'));                                    
              });
        }).catch((error)=>{
                        
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not process registration','error'));
        });
    }   

}

export function SignUpComplete(sfield) {
    return {
        type: 'SIGNUP_COMPLETE',
        field:sfield,
                 
    }
}


export function getSearchSuggestions(keywords,location,date) {
    
    return function(dispatch) {
        
        fetch(API_URL+'/api/search/get?key='+keywords+(location !== null && location !== undefined ? '&location='+location:"")+(date !== null && date !== undefined ? '&date='+(date.getFullYear())+'-'+(date.getMonth()+1)+'-'+(date.getDate()):""),{ 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            },
            
        }).then(handleHTTPError)  
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                  
                  dispatch(storeSearchSuggestions(json.data));             
              });
        }).catch((error)=>{
                        
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not process registration','error'));
        });
    }   

}

export function storeSearchSuggestions(results) {
    return {
        type: 'STORE_SEARCH_SUGGESTIONS',
        locations:results.locations,
        activities:results.activities         
    }
}


export function MyBookings() {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/my-bookings',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActivities(json.data.bookings));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}



export function storeActivities(list) {
    return {
        type: 'STORE_MY_BOOKINGS',
        list: list
    }
}


export function CancelBooking(bookingId) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/my-bookings/cancel/'+bookingId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(MyBooking(bookingId));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}


export function MyBooking(bookingId) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/my-bookings/show/'+bookingId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActiveBooking(json.data));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}



export function storeActiveBooking(data) {
    return {
        type: 'STORE_ACTIVE_BOOKING',
        booking: data
    }
}

export function AddMessage(bookingId,data) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Sending...','info'));
        fetch(API_URL+'/api/account/my-bookings/'+bookingId+'/comments',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetMessages(bookingId));
                  dispatch(SnackBar(true,'Message sent...','success'));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

export function GetMessages(bookingId) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/my-bookings/'+bookingId+'/comments',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActiveBookingMessages(json.data));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}



export function storeActiveBookingMessages(messages) {
    return {
        type: 'STORE_ACTIVE_BOOKING_MESSAGES',
        messages: messages
    }
}


export function GetReviews(bookingId) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/reviews',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeReviews(json.data));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'No data found','info'));
        });
    }   

}



export function storeReviews(data) {
    return {
        type: 'STORE_REVIEWS',
        reviews: data.reviews
    }
}


export function ValidateBookingForm(data,agreement){

    //var firstName = this.state.CartItems[0].booking_object.traveller[0].firstname;
	//		var lastName  = this.state.CartItems[0].booking_object.traveller[0].lastname;
	///		var email = this.state.CartItems[0].booking_object.traveller[0].email;
     //       var phone = this.state.CartItems[0].booking_object.traveller[0].phone;
            
    return function(dispatch) {
        for(var x=0; x < data.length; x++){
            
            if(data[x].booking_object.traveller[0].firstname.length === 0){
                dispatch(SnackBar(true,'Lead traveler first name can not be empty','error'));
                return false;
            }

            if(data[x].booking_object.traveller[0].lastname.length === 0){
                dispatch(SnackBar(true,'Lead traveler last name can not be empty','error'));
                return false;
            }

            if(data[x].booking_object.traveller[0].email.length === 0){
                dispatch(SnackBar(true,'Lead traveler email can not be empty','error'));
                return false;
            }

            if(data[x].booking_object.traveller[0].phone.length === 0){
                dispatch(SnackBar(true,'Lead traveler phone can not be empty','error'));
                return false;
            }

            if(data[x].booking_object.pickup_location === 'hotel'){

                if(data[x].booking_object.hotel_name.length === 0){
                    dispatch(SnackBar(true,'Pick-up hotel name can not be empty','error'));
                    return false;
                }

                if(data[x].booking_object.hotel_address.length === 0){
                    dispatch(SnackBar(true,'Pick-up hotel address can not be empty','error'));
                    return false;
                }                
                
            }

            if(data[x].booking_object.pickup_location === 'airport'){

                if(data[x].booking_object.flight_no.length === 0){
                    dispatch(SnackBar(true,'Pick-up flight number can not be empty','error'));
                    return false;
                }

                if(data[x].booking_object.flight_time.length === 0){
                    dispatch(SnackBar(true,'Pick-up flight time can not be empty','error'));
                    return false;
                }                
                
            }


            if(data[x].booking_object.contact_firstname.length === 0){
                dispatch(SnackBar(true,'Contact first name can not be empty','error'));
                return false;
            }

            if(data[x].booking_object.contact_lastname.length === 0){
                dispatch(SnackBar(true,'Contact last name can not be empty','error'));
                return false;
            }

            if(data[x].booking_object.contact_email.length === 0){
                dispatch(SnackBar(true,'Contact last name can not be empty','error'));
                return false;
            }

            if(data[x].booking_object.contact_phone.length === 0){
                dispatch(SnackBar(true,'Contact phone can not be empty','error'));
                return false;
            }

            
        }

        if(agreement === false){
            dispatch(SnackBar(true,'You need to agree to terms & conditions','error'));
            return false;
        }

        dispatch(ConfimEmail(true,data,(data)=>dispatch(MakeBooking(data))));
        
    }       
     
    

    
}

export function MakeBooking(data) {
     
    return function(dispatch) {
        
        dispatch(startLoading());
        dispatch(SnackBar(true,'Placing your booking','info'));
        fetch(API_URL+'/api/booking/checkout',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'              
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(SnackBar(true,'Sending you for payment.','success'));
                  window.location.href=json.data.payment_link;                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not process the booking','error'));
        });
    }   

}

export function FetchSliders(){

    return function (dispatch){
        dispatch(ShowLoadingSpinner('bannersLoading',true));
        fetch(API_URL+'/api/sliders/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(StoreSliders(json));                  
                  dispatch(endLoading());
                  dispatch(ShowLoadingSpinner('bannersLoading',false));
          });
    }
}


export function StoreSliders(list){
    return{type:'STORE_SLIDERS',slides:list};
}


export function FetchFeaturedCat(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/featured/categories/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(StoreFeaturedCat(json));                  
                  dispatch(endLoading());
          });
    }
}


export function StoreFeaturedCat(list){
    return{type:'STORE_FEATURED_CATS',cats:list};
}


export function GetSiteSettings(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/settings/site/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',                            
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(StoreSiteSettings(json));                  
                  dispatch(endLoading());
          });
    }
}


export function StoreSiteSettings(config){
    return{type:'STORE_SITE_SETTINGS',settings:config};
}

export function GetSiteContent(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/content/site/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',                            
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(StoreSiteContent(json));                  
                  dispatch(endLoading());
          });
    }
}


export function StoreSiteContent(config){
    return{type:'STORE_SITE_CONTENT',settings:config};
}

export function GetLocationContent(pageId){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/location/page?id='+pageId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',                            
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(StoreLocationContent(json));                  
                  dispatch(endLoading());
          });
    }
}


export function StoreLocationContent(config){
    return{type:'STORE_LOCATION_CONTENT',p:config};
}

export function FetchInstagram(){

    return function (dispatch){

       
        dispatch(startLoading());
        fetch(API_URL+'/api/instagram/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/jsonp;charset=utf-8',                            
            },
            
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(StoreInstaContent(json));                  
                  dispatch(endLoading());
          });
    }
}

export function StoreInstaContent(data){
    return{type:'STORE_INSTAGRAM_FEED',data:data};
}


export function GetDeals() {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/all/deals',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeFeaturedDeals(json.data));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'No data found','info'));
        });
    }   

}

export function storeFeaturedDeals(data) {
    return {
        type: 'STORE_FEATURED_DEALS',
        list: data
    }
}

export function SearchLocation(SearchKeyword){
    return function(dispatch){
        fetch(API_URL+'/api/locations/search?keyword='+SearchKeyword,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeSearchLocations(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'No data found','info'));
        });
    }
}

export function storeSearchLocations(data) {
    return {
        type: 'STORE_SUGGESTED_LOCATIONS',
        list: data
    }
}


export function UpdateSelectedLocation(location) {
    return function(dispatch){
        dispatch(SaveLocation(location));
        dispatch(storeSearchLocations([]));
    }
}

export function SaveLocation(location) {
    return {
        type: 'UPDATE_SELECTED_LOCATION',
        location: location
    }
}

export function UpdateSelectedDate(date) {
    return function(dispatch){
        dispatch(SaveDate(date));
        dispatch(storeSearchLocations([]));
    }
}

export function SaveDate(date) {
    return {
        type: 'UPDATE_SELECTED_DATE',
        date: date
    }
}

