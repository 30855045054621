import React, { Props, Component, Fragment } from 'react';

import SearchResultLIstViewItem from './Common/SearchResultLIstViewItem';
import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { getSearchSuggestions,GetReviews } from '../Actions/WebsiteActions';
import { Link } from 'react-router-dom';
import AccountNavigation from './Common/AccountNavigation';
import { FeaturedActivities } from '../Actions/ActivityActions';
import StarRatingComponent from 'react-star-rating-component';

import ReviewModal from './Common/ReviewModal';


const mapStateToProps = (state) => {
	return {
		searchResultsLocations: state.Website.searchResultsLocations,
		searchResultsActivities: state.Website.searchResultsActivities,
		featuredTours:state.Website.featuredTours,
		user:state.Auth.user,
		myreviews:state.Website.myreviews
	}
}

const mapDispatchToProps = dispatch => ({
	getSearchSuggestions: (keyword) => dispatch(getSearchSuggestions(keyword)),
	FeaturedActivities:()=>dispatch(FeaturedActivities()),
	GetReviews: () => dispatch(GetReviews())
});
class MyAccount extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
			activity:null,
			openModal:false,
		}

		this.props.GetReviews();
		this.props.FeaturedActivities();
	}


	closeModal = () => {
		this.setState({ openModal:false});
	}

	getDuration(measure){        
		switch(measure){
		  case 1 :{ return "Days" }
		  case 2 :{ return "Hours" }
		  case 3 :{ return "Minutes" }
		}
	  }

	handleChange = (event, field) => {

		this.setState({
			[field]: event.target.value
		}, () => {

			this.props.getSearchSuggestions(this.state.searchField);

		});
	}


	render() {
		return <Fragment>
			<Header  />
			<section class="body-gry setting-pg">
	<div class="container">
		<div class="row">
			<div class="col l4 m5 s12">
			<AccountNavigation/>
			</div>

			<div class="col l8 m7 s12">
				<div class="set-cont">
					<div class="hed-bed">
						<div class="bred-cmp">
							<a href="">Dashboard</a>
						</div>
						<h1>Hello, {this.props.user.first_name} {this.props.user.last_name}</h1>
					</div>
					<div class="set-act-div set-my-account">

								{ this.props.myreviews !== null && this.props.myreviews.length > 0 ? (
									<div class="hiyla">
									<label>How is your last Activity ? </label>
									<span class="ret rev-st">
										<StarRatingComponent
											name="rate2"
											editing={false}
											renderStarIcon={() => <span></span>}
											starCount={5}
											value={this.props.myreviews[0].reviews.rating}
											renderStarIcon={() => <i style={{ fontSize: 36, fontStyle: 'normal' }} >★</i>}
											/>
									</span>
									<div>
										{ this.props.myreviews[0].reviews.rating === 0 ? (<a class="waves-effect waves-light btn" onClick={()=>{this.setState({openModal:true, activity:this.props.myreviews[0]})}} >Review Now</a>):<div style={{height:25}} ></div>} 
										
									</div>
								</div>
								):null}

								{ this.props.myreviews !== null && this.props.myreviews.length > 0 ? (
									<div class="recent">
									<div class="rs-img">
										<img src={this.props.myreviews[0].activity.cover_image} />
										<span>Recent</span>
									</div>
									<div class="rs-dit">
										<h6>{this.props.myreviews[0].activity.title}</h6>
										<div class="lc-rw">											
											<p><span>Duration</span>{this.props.myreviews[0].activity.duration}&nbsp;{this.getDuration(this.props.myreviews[0].activity.duration)}</p>
										</div>										
										<div class="pr-rw">
											<span>Price</span>
											<p>$1,076</p>
										</div>
									</div>
								</div>
								):null}
								
								
								<div class="clear"></div>
						<div class="lis-hed">
							<h6>We would Suggest</h6>
						</div>
						{this.props.featuredTours !== null ? this.props.featuredTours.map((item,index)=>{
							return <SearchResultLIstViewItem data={item} key={'lv-'+index} />;
						}):null}
						<div class="hr"></div>
						
					</div>

				</div>
			</div>
		</div>
	</div>
	<ReviewModal onClose={this.closeModal} data={this.state.activity} open={this.state.openModal} />
</section>
								<Footer />

                </Fragment>
							}
						}
						
export default compose(connect(mapStateToProps, mapDispatchToProps))(MyAccount);