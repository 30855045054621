import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { registerAccount } from '../Actions/WebsiteActions';

import Header from './Common/Header';
import Footer from './Common/Footer';
import SearchSideBar from './Common/SearchSideBar';
import RoundTourItinery from './Common/RoundTourItinery';
import PricingWidget from './Common/PricingWidget';
import { GetActivity,RelatedActivities } from '../Actions/ActivityActions';
import StarRatingComponent from 'react-star-rating-component';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Helmet} from "react-helmet";

import {Redirect} from 'react-router-dom'


const mapStateToProps = (state) => {
	return {
		activeTour:state.Activities.activeTour,
		relatedTours:state.Activities.relatedTours,
		BookNow:state.Cart.bookNow,
	}
}

const mapDispatchToProps = dispatch => ({
	createAccount: (data, UserType) => dispatch(registerAccount(data, UserType)),
	GetActivity:(aid)=>dispatch(GetActivity(aid)),
	RelatedActivities:(cats)=>dispatch(RelatedActivities(cats))
	
});
class ActivityDetails extends Component {


	constructor(props) {
		super(props);
	
		const { aid } = this.props.match.params;

		this.state = {
			value:0,
			relatedLoaded:false,	
		}

		if(aid !== undefined){
				this.props.GetActivity(aid);
		}
	}

	sliderREf;

	setSliderRef = ref => { this.sliderREf = ref };

	handleTabChange = (event,NewValue)=>{
		this.setState({value:NewValue});
	}

	handleUpdate = (event, field) => {
		this.setState({
			[field]: event.target.value
		})
	}

	doRegistration = () => {
		this.props.createAccount(this.state, 3);
	}

	getTripType(type){
        
		switch(type){
		  case 1 :{ return "Round tour" }
		  case 2 :{ return "Day Tour" }
		  case 3 :{ return "Activity" }
		}
	}
	
	getDuration(measure){        
	  switch(measure){
		case 1 :{ return "Days" }
		case 2 :{ return "Hours" }
		case 3 :{ return "Minutes" }
	  }
	}
	
	
	getStatus(status){        
	  switch(status){
		case 0 :{ return "Pending" }
		case 1:{ return "Active" }
		case 2 :{ return "Rejected" }
	  }
	}

	getPricingBasis(status){ 
	       
	  switch(status){    
		case '1':{ return "Per Person" }
		case '2' :{ return "Per Group" }
	  }
	}

	componentDidUpdate(){
		
		if(this.props.activeTour.images !== null){
			if(window.$.flexslider){
				window.$(this.sliderREf).flexslider('destroy');
				window.$(this.sliderREf).flexslider({
					animation: "slide",    
					controlNav: "thumbnails",
				});
			}
		}
		


		if(this.state.relatedLoaded === false ){

			this.setState({
				relatedLoaded:true
			})
			
			if(this.props.activeTour.category[0] !== undefined){
				var cats = [];
				for(var x=0; x < this.props.activeTour.category.length; x++){
					cats.push(this.props.activeTour.category[x].category_id);
				}
	
				this.props.RelatedActivities(cats.join());
			}
		}
		
		
		if(this.props.BookNow === true){
			window.location.href='/checkout';
		}
		
	}

	handleAddBooking = () =>{

	}

	render() {
		return <Fragment>
			<Header />
					<Helmet>
						<meta charSet="utf-8" />
						<title>{this.props.activeTour.title}</title>
						<meta property="og:title" content={this.props.activeTour.title} />
						<meta property="og:type" content="article" />
						<meta property="og:url" content={this.props.activeTour.seo_url} />
						<meta property="og:image" content={this.props.activeTour.cover_image} />						
					</Helmet>
			<section className="body-gry">
	<div className="container">
		<div className="row">
			<div className="col s12">
				<div className="bed-det">
					<a href="/">Home</a>  /   <a href="">{this.getTripType(this.props.activeTour.tour_type)}</a>  /  <span>{this.props.activeTour.title}</span>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col l3 m5 s12 ipad-none">
				<SearchSideBar location={this.props.location}/>
			</div>

			<div className="col l6 m7 s12">
				<div className="tour-deit">

					<div className="dei-silder">
						<div className="flex-div">
							<div  ref={this.setSliderRef}>
							  <ul className="slides">

								{this.props.activeTour.images !== null ? this.props.activeTour.images.map((item)=>{
									return <li key={item.id} data-thumb={item.url} >
												<img src={item.url} alt={item.alt_text} />
											</li>			
								}):null}
												
							    
							  </ul>
							</div>
							<div className="sid-rew">
								<div className="ratings">Supplier Ratings <span>0/10</span></div>
								<div className="reviews">Customer Reviews 
									<span>
									<StarRatingComponent
                          name="rate2"
                          editing={false}
                          renderStarIcon={() => <span></span>}
                          starCount={5}
                          value={this.props.activeTour.average_rating}
                          renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal',color:'#d6a230' }} >{this.props.activeTour.average_rating === null ? ('☆'):'★' }</i>}
                        />
									</span>
								</div>
							</div>
						</div>
						<div className="cont-sid">
							<div>
								<p><span>Tour ID</span>{this.props.activeTour.tour_id}</p>
								<p><span>Duration</span>{this.props.activeTour.duration_value}&nbsp;{this.getDuration(this.props.activeTour.duration)} </p>
							</div>
							<div className="text-right">
								<p><span>Selling Count</span>{this.props.activeTour.sold}</p>
								<p><span>Category</span>{ this.props.activeTour.category[0] !== undefined ? this.props.activeTour.category[0].category.name : null } { this.props.activeTour.category.length > 1 ? (<em>+{this.props.activeTour.category.length-1} more</em>) : null } </p>
							</div>
							<div className="clear"></div>
						</div>
					</div>

					<h4>{this.props.activeTour.title}</h4>

					<div className="tab-section">

					<div className="tab-li">				
						<Tabs value={this.state.value} indicatorColor="primary" textColor="primary"  className="tabs" onChange={this.handleTabChange} >
						    <Tab label="itinerary" className="tab"  />
							<Tab label="Includes" className="tab"  />						
							<Tab label="Excludes" className="tab" />
							<Tab label="Conditions" className="tab" />
							<Tab label="Know Before You Go" className="tab" />
						</Tabs>
								
						<div className="tab-cont">
						{this.state.value === 0 && <div  className="col s12 tbc">
								
								
									<p>{this.props.activeTour.overview}</p>
								
								
								{this.props.activeTour.tour_type === 1 ? (
									<RoundTourItinery itinery={this.props.activeTour.itinery} />
								):<p style={{whiteSpace:'pre-wrap'}}>
									{this.props.activeTour.itinery[0] !== undefined ? this.props.activeTour.itinery[0].summery:""}
								</p>}</div>}

								{this.state.value === 1 && <div  className="col s12 tbc">
								<ul>								
									{this.props.activeTour.inclusion !== null ?  this.props.activeTour.inclusion.map((item)=>{
										return <li>{item}</li>
									}):null}
								</ul>
								
								</div>}		

						{this.state.value === 2 && <div  className="col s12 tbc">
						<ul>	
								{this.props.activeTour.exclision !== null ?  this.props.activeTour.exclision.map((item)=>{
									return <li>{item}</li>
								}):null}
						</ul>
							
						</div>}
						{this.state.value === 3 && <div  className="col s12 tbc">
							<ul>
								{this.props.activeTour.condtions !== null ?  this.props.activeTour.condtions.map((item)=>{
									return <li>{item}</li>
								}):null}
							</ul>
							
							<br/>
							<b style={{fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',color:'#f8a41b'}}>CANCELLATION POLICY</b>
							<p>{this.props.activeTour.cancel_policy}</p>
							</div>}
						{this.state.value === 4 && <div  className="col s12 tbc">
						<ul>
								{this.props.activeTour.know_policy !== null ?  this.props.activeTour.know_policy.map((item)=>{
									return <li>{item}</li>
								}):null}

						</ul>
							
							</div>}			
						<div style={{clear:'both'}}></div>	
					</div>
					</div>			
							

						
						
					</div>
				</div>
			</div>

			<div className="col l3 m5 s12">
				<PricingWidget activeTour={this.props.activeTour} />
				<div className="related_tours">
					<h3>Related Tours</h3>
					{ this.props.relatedTours !== undefined && this.props.relatedTours !== null ? this.props.relatedTours.map((item,index)=>{
						return <a href={'/activity/details/'+item.id}>
										<h4>{item.title.substring(0,50)}</h4>
										<p>{item.overview.substring(0,75)}...</p>
									</a>;
					}):null}					
					
				</div>
			</div>

		</div>
	</div>
</section>

			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActivityDetails);