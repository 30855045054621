import React, { Props, Component, Fragment } from 'react';
import Alerts from './Alert';
import LoginModal from './LoginModal';

import { compose } from 'redux';
import { connect } from 'react-redux';

import {Link} from 'react-router-dom'
import { getSearchSuggestions } from '../../Actions/WebsiteActions';
import { SnackBar } from '../../Actions/CommonActions';
import EmailConfirmation from './EmailConfirmation';



const mapStateToProps = (state) => {
    return {
		searchResultsLocations:state.Website.searchResultsLocations,
        searchResultsActivities:state.Website.searchResultsActivities,
        loggedIn:state.Auth.loggedIn,
        CartItems:state.Cart.items,
    }
  }
  
  const mapDispatchToProps = dispatch => ({		
        getSearchSuggestions:(keyword)=>dispatch(getSearchSuggestions(keyword)),
        SnackBar:(show,message,varient)=>dispatch(SnackBar(show,message,varient))        
  });
class Header extends Component {

    constructor(props){
        super(props);

        this.state = {
            searchField: (this.props.search !== undefined && this.props.search !== null) ? this.props.search : "",
            searchOnFocus:false,        
        }
        
        
    }

    

    handleChange = (event,field) => {

		this.setState({
			[field]:event.target.value
		},()=>{

			this.props.getSearchSuggestions(this.state.searchField);

		});
	}

    render() {
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="logo">
                                <a href="/">
                                    <img src="/img/logo-wit.png" />
                                </a>
                                { this.props.home == false ? (<div className="inp">
                                    <div className="fm">
                                        <input placeholder='e.g, "Ella Rock"' id="hedsrh" type="text" name="" onBlur={()=>{ this.setState({searchOnFocus:false}) }} onFocus={()=>{ this.setState({searchOnFocus:true}) }} value={this.state.searchField} onChange={(e)=>{ this.handleChange(e,'searchField'); }} />
                                        <input type="submit" value="" name="" onClick={()=>{ window.location.href="/search?key="+this.state.searchField+(this.state.searchField !== null && this.state.searchField !== "" ? "&view=list":"") }} style={{cursor:'pointer'}} />
                                    </div>
                                    <div className={"sr-res "+(this.state.searchOnFocus === true ? 'open':null)} id="hedsrh-frm">
                                        {/* <a className="tu" href="">Central Sri Lanka</a>
                                        <a className="tu" href="">Central Sri Lanka</a>
                                        <div className="sepr"></div>
                                        <a className="des" href="">Northern Sri Lanka</a>
                                        <a className="des" href="">Northern Sri Lanka</a>
                                        <div className="sepr"></div> */}
                                        {this.props.searchResultsActivities !== null ? this.props.searchResultsActivities.map((activity)=>{												
												return  <a href={activity.seo_url}>{activity.title}</a>;    		
										}):null}
                                        
                                        
                                    </div>
                                </div>):null}
                                

                            </div>
                            <div className="rit_menu">
                                <Link to="/help">Help</Link> 
                                <div>
                                    {this.props.loggedIn }
                                    <Link className="dropdown-trigger" to={this.props.loggedIn === true ? '/account':'/login'}><img src="/img/man-user.png" /></Link>                                    
                                    
                                </div>
                                {this.props.CartItems.length === 0 ? (
                                    <a onClick={()=>this.props.SnackBar(true,"Your cart is empty",'info')} style={{cursor:'pointer'}} className="cart">
                                    <span>{this.props.CartItems.length}</span>
                                </a>
                                ): <Link to={'/cart'} className="cart">
                                    <span>{this.props.CartItems.length}</span>
                                </Link> }
                                
                            </div>
                        </div>
                    </div>
                </div>
                <Alerts/>
                <EmailConfirmation/>
                <LoginModal/>
            </header >
        );
    }
}

Header.defaultProps = {
    home: false,
  }


export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);