export default function reducer(state={
    list: new Array(),
    activeTour: {
                    id:0,
                    title:"",
                    tour_type:0,
                    duration:0,
                    duration_value:0,
                    category: [],
                    overview:null,
                    adults_age_from:null,
                    adults_age_to:null,
                    children_age_from:null,
                    children_age_to:null,
                    infant_age_from:null,
                    infant_age_to:null,
                    inclusion:null,
                    exclision:null,
                    know:null,
                    condtions:null,
                    contact_number:null,
                    images: new Array(),
                    itinery: new Array(),
                    available_locations:[],
                    know_policy:[],
                    average_rating:null,
                    cancel_policy:null,
                    tour_id:null,
                    sold:null,
                    supplier_name:null,
                    seo_url:"",
                    updated:false,
                },
    activeEstimationFormatted:"",
    activeEstimation:0,
    minBookingDate: null,    
    searchResults:null,            
    relatedTours:[],
    featuredSearchedItems:null,
    mostPopularRoudTours:null,
    mostPopularDayTours:null,    
},action){
switch(action.type){
    case "STORE_ACTIVITIES":{
        return{...state,list:action.list}
    }
    case "STORE_SEARCH_ACTIVITIES":{
        return{...state,searchResults:action.list}
    }
    case "STORE_SEARCH_ACTIVITIES_FEATURED":{
        return{...state,[action.var]:action.list}
    }
    case "STORE_ACTIVE_ACTIVITY":{
        return{...state,
                    activeTour:{
                                    id:action.activity.id,
                                    title:action.activity.title,
                                    tour_type:action.activity.tour_type,
                                    duration:action.activity.duration,
                                    duration_value:action.activity.duration_value,
                                    category: action.activity.category,
                                    overview:action.activity.overview,
                                    adults_age_from:action.activity.adults_age_from,
                                    adults_age_to:action.activity.adults_age_to,
                                    children_age_from:action.activity.children_age_from,
                                    children_age_to:action.activity.children_age_to,
                                    infant_age_from:action.activity.infant_age_from,
                                    infant_age_to:action.activity.infant_age_to,
                                    inclusion:action.activity.inclusion,
                                    exclision:action.activity.exclision,
                                    know:action.activity.know,
                                    condtions:action.activity.condtions,
                                    contact_number:action.activity.contact_number,
                                    images: action.activity.images,
                                    itinery: action.activity.itinery,
                                    available_locations: action.activity.available_locations,
                                    know_policy:action.activity.know_policy,
                                    average_rating:action.activity.average_rating,
                                    cancel_policy:action.activity.cancel_policy,
                                    tour_id:action.activity.tour_id,
                                    sold:action.activity.sold,
                                    supplier_name:action.activity.supplier_name,
                                    seo_url:action.activity.seo_url,
                                    updated:true,
                                }
              }
    }
    case "LOCK_ACTIVE_ACTIVITY":{
        return{...state,
                    activeTour:{                                    
                                    updated:false,
                                }
              }
    }    
    case "LOADIND_END":{ 
        return{...state,loading:false}
    }
    case "UPDATE_TOUR":{
        return {...state,activeTour:action.tour}
    } 
    case "UPDATE_ESTIMATION":{
        return {...state,activeEstimationFormatted:action.amount_formatted,activeEstimation:action.amount,minBookingDate:new Date(action.min_date)}
    }
    
    case "RELATED_TOURS":{
        return {...state,relatedTours:action.list}
    }
    
}
return state
}