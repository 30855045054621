import React, { Props, Component, Fragment } from 'react';

import SearchResultGridItem from './SearchResultGridItem';
import StarRatingComponent from 'react-star-rating-component';

import {Link} from 'react-router-dom'

class SearchResultLIstViewItem extends Component {

	constructor(props) {
		super(props)

		this.state = {
			searchedLocations:[]
		}
	}

	getTripType(type){
        
		switch(type){
		  case 1 :{ return "Round tour" }
		  case 2 :{ return "Day Tour" }
		  case 3 :{ return "Activity" }
		  case 4 :{ return "Transport" }
		}
	}

	componentDidMount =()=>{
		if(this.props.searchParams !== undefined && this.props.searchParams.location !== ""){
			this.setState({
				searchedLocations:this.props.searchParams.location.split(',')
			},()=>console.log(this.state.searchedLocations));
		}	
		
	}

	componentDidUpdate = () =>{
		this.getPickupLocations();
	}

	getPickupLocations = () =>{
		if(this.props.data != null && this.props.data != undefined && this.state.searchedLocations !== undefined ){
			var matchedLocations = [];

			for(var x=0; x < this.props.data.available_locations.length;x++){
				
				if(this.state.searchedLocations.includes(this.props.data.available_locations[x].loc_id.toString())){
					matchedLocations.push(this.props.data.available_locations[x]);
						
				}
			}


			if(matchedLocations.length > 0){
				return <Fragment>{matchedLocations[0].location_name}&nbsp;{ matchedLocations.length > 1 ? (<em>+{matchedLocations.length-1} more</em>) : null }</Fragment>;
			}
		}
	}
	
	render() {
		
		
		return (
			<div className="list-vw">

				
				{/* <span className="off">
					<p>40%</p>
				</span> */}
				<div className="img">
				{ this.props.data.deals !== undefined ? (<span class="off"><p>{this.props.data.deals.subscription.deals.discount}%</p></span>) : null }
					{this.props.data.activity_images !== null ? (

						<img src={this.props.data.cover_image !== null ? this.props.data.cover_image :null} />
					):null}
					
				</div>
				<div className="lsit-cont">
					<h4>{this.props.data.title}</h4>
					<div className="reviews2">
						<tr>
							<td style={{padding:0}}><span className="reviews" style={{minWidth:55}}>Reviews</span></td>
							<td style={{padding:0}}><StarRatingComponent
                          name="rate2"
                          editing={false}
                          renderStarIcon={() => <span></span>}
                          starCount={5}
                          value={this.props.data.average_rating}
                          renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal',color:'#d6a230' }} >{this.props.data.average_rating === null ? ('☆'):'★' }</i>}
                        /></td>
						</tr>
						
						
					</div>
					<p>{this.props.data.overview.substr(0, 90)}...</p>
					<div>
						<p><span>CATEGORIES	</span>{this.props.data.category[0].category.name} { this.props.data.category.length > 1 ? (<em>+{this.props.data.category.length-1} more</em>) : null } </p>
						{/* <p><span>STARTS/ ENDS IN </span>Negombo / Colombo</p> */}
						{/* <p><span>ACCOMMODATION </span>Hotel, Lodge<em>+1 more</em></p> */}
						{ this.state.searchedLocations.length > 0 ? (<p><span>PICK-UP LOCATIONS	</span>{this.getPickupLocations()}</p>):null }
						<p><span>TOUR TYPE </span>{this.getTripType(this.props.data.tour_type)}</p>
						<p><span>OPERATOR </span>{this.props.data.user.supplier_profile.work_name}</p>
					</div>
				</div>
				<div className="list-pri">
					<div className="pric-side">
						<span className="price">USD {this.props.data.price.selling_price}</span>
						<span className="st-price">Starting Price</span>
					</div>
					
					{ this.props.data.tour_type === 1 ? (
						<Fragment>
							<div className="db-div">
								{ this.props.data.sale_type === 1 ? (<div className="db book">Instant Booking </div>):(<div className="db book">On Availability </div>) }
								
								<div className="db deposit"><span />Lowest Price Guarantee</div>
								<div className="db deposit"><span />Free Cancellation</div>
							</div>
							{/* <p>*Consetetur sadipscing elitr, sed diam nonumy eirmod tempor </p> */}
						</Fragment>
					):null}


				{ this.props.data.tour_type === 2 ? (
						<Fragment>
							<div className="db-div">
							{ this.props.data.sale_type === 1 ? (<div className="db book">Instant Booking </div>):(<div className="db book">On Availability </div>) }
								<div className="db deposit"><span />Lowest Price Guarantee</div>
								<div className="db deposit"><span />Great selection</div>
							</div>
							{/* <p>*Consetetur sadipscing elitr, sed diam nonumy eirmod tempor </p> */}
						</Fragment>
					):null}

					
					
					<div className="tur-btn">
						<a className="waves-effect waves-light btn" href={this.props.data.seo_url} >View Tour</a>
					</div>
				</div>
				<div className="clear"></div>
			</div>

		);
	}
}

SearchResultLIstViewItem.defaultProps = {
	home: false,
}


export default (SearchResultLIstViewItem);