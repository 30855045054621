import React, { Props,Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { getSearchSuggestions } from '../Actions/WebsiteActions'; 
import {Link} from 'react-router-dom'
import {Redirect} from 'react-router-dom'
import {Helmet} from "react-helmet";

import SearchResultGridItem from './Common/SearchResultGridItem';
import { GetLocationContent } from '../Actions/WebsiteActions';
import { SnackBar } from '../Actions/CommonActions';
import Alert from './Common/Alert';
const $ = window.jQuery;

const mapStateToProps = (state) => {
    return {
		loading : state.Common.loading,
		loggedIn : state.Auth.loggedIn,
		aboutus : state.Website.site_aboutus,
		activePage:state.Website.activePage, 
    }
  }
  
  const mapDispatchToProps = dispatch => ({		
	GetLocationContent:(pageId)=>dispatch(GetLocationContent(pageId))
  });
class LocationPage extends Component{

	owlPopularDayTours	 = React.createRef();

	constructor(props){
		super(props);

		const { pid } = this.props.match.params;

		this.state = {
            username: "",
            password:"",
            loggedIn:false,
			loading:false,
			id:0,
			location:"",      
			title:"",
			description:"",
			seo_title:"",
			seo_description:"",
			seo_url:"",
			full_seo_url:"",
			image_alt:"",
			activities:[],            
			image_name:null,
			uploadImage:null,
			location_data:null,
			dayToursLoaded:false
		};
		
		if(pid !== null && pid !== undefined){
			this.props.GetLocationContent(pid);
		}
		
	}

	componentDidMount() {
        window.scrollTo(0,0)
	  }
	  
	handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
	  };
	  

	  componentWillReceiveProps = (nextProps) =>{
		this.setState({
		  id:nextProps.activePage.id,
		  location:nextProps.activePage.location,      
		  title:nextProps.activePage.title,
		  description:nextProps.activePage.description,
		  seo_title:nextProps.activePage.seo_title,
		  seo_description:nextProps.activePage.seo_description,
		  seo_url:nextProps.activePage.seo_url,
		  full_seo_url:nextProps.activePage.full_seo_url,
		  image_alt:nextProps.activePage.image_alt,
		  activities:nextProps.activePage.activities,            
		  image_name:nextProps.activePage.image_name,
		  location_data:nextProps.activePage.location_data 
		});
	}
	 
	componentDidUpdate = () =>{

		

		if(this.state.activities.length > 0 && this.state.dayToursLoaded === false){

			$(this.owlPopularDayTours.current).owlCarousel({
				loop: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 0
					},
					600: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 10,
					},
					1000: {
						items: 4,
						loop: false,
						nav: false,
						dote: false,
						margin: 30,
					}
				}
			});

			this.setState({
				dayToursLoaded:true
			})
		}

		

		
	}
	
	componentDidMount() {
        window.scrollTo(0,0)
      }


    render(){

		var dayTours = 0;

        return <Fragment>
                    <Header home={false}/>
					<Helmet>
						<meta charSet="utf-8" />
						<title>{this.state.title}</title>
						<meta property="og:title" content={this.state.title} />
						<meta property="og:type" content="article" />
						<meta property="og:url" content={this.state.full_seo_url} />
						<meta property="og:image" content={this.state.image_name} />						
					</Helmet>
					<Alert/>
                    <section className="bedcmp-section">
	<div className="container">
		<div className="row">
			<div className="col l12">
				<div className="hed-bed">
					<div className="bred-cmp">
						<a href="">Home</a> / <span>{this.state.location_data !== null ? this.state.location_data.name:null}</span> / <span>{this.state.location_data !== null ? this.state.title:null}</span>
					</div>
					<h1>{this.state.title}</h1>
				</div>
			</div>
		</div>
	</div>
</section>
<section>
	<div class="container">
		<div class="row">
			<div class="col l7 m7 s12">
				<div class="abou-cont">
					<p>{this.state.description}</p>
					
				</div>
			</div>
			<div class="col l5 m5 s12">
				<div class="abot-img">
					<img src={this.state.image_name} style={{display:'block',width:'100%',height:'auto',position:'relative'}} />
				</div>
			</div>
		</div>
	</div>
</section>
<section class="abou-gry">
	<div class="container">
	<div class="row">
					<div class="col l12 m12 s12">
					
						<div class="owl-carousel owl-theme owl-tour-scr rm-hid" ref={this.owlPopularDayTours} >
							
						{
							
							this.state.activities.length > 0 ? this.state.activities.map((item,index)=>{
							
							dayTours++;	
							return <SearchResultGridItem searchParams={this.props.searchParams} data={item} key={"gv-"+index} />;
						}):null}						
						</div>
					</div>
				</div>
		<div class="row">
			<div class="col l7">
				<div class="row">
					<div class="col l6 s12">
						<div class="cont-abou">
							<h5>Connect with Us!</h5>
							<p>Want to know more? Join us </p>
						</div>
					</div>
					<div class="col l6 s12">
						<div class="cont-abou">
							<ul>
								<li><a class="fb" href=""></a></li>
								<li><a class="lin" href=""></a></li>
								<li><a class="twi" href=""></a></li>
								<li><a class="you" href=""></a></li>
								<li><a class="pin" href=""></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<Footer/>

                </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(LocationPage);