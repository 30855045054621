import React, { Props,Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { getSearchSuggestions } from '../Actions/WebsiteActions'; 
import {Link} from 'react-router-dom'
import {Redirect} from 'react-router-dom'


import { GetSiteContent } from '../Actions/WebsiteActions';
import { SnackBar } from '../Actions/CommonActions';
import Alert from './Common/Alert';

const mapStateToProps = (state) => {
    return {
		loading : state.Common.loading,
		loggedIn : state.Auth.loggedIn,
		aboutus : state.Website.site_aboutus 
    }
  }
  
  const mapDispatchToProps = dispatch => ({		
	GetSiteContent:()=>dispatch(GetSiteContent())
  });
class About extends Component{

	constructor(props){
		super(props);

		this.state = {
            username: "",
            password:"",
            loggedIn:false,
            loading:false,
		};
		
		this.props.GetSiteContent();
	}

	componentDidMount() {
        window.scrollTo(0,0)
	  }
	  
	handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
	  };
	  

	  
	
	componentDidMount() {
        window.scrollTo(0,0)
      }


    render(){
        return <Fragment>
                    <Header home={false}/>
					<Alert/>
                    <section className="bedcmp-section">
	<div className="container">
		<div className="row">
			<div className="col l12">
				<div className="hed-bed">
					<div className="bred-cmp">
						<a href="">Home</a> / <span>About Us</span>
					</div>
					<h1>About us</h1>
				</div>
			</div>
		</div>
	</div>
</section>
<section>
	<div class="container">
		<div class="row">
			<div class="col l7 m7 s12">
				<div class="abou-cont">
					<p>{this.props.aboutus}</p>
					
				</div>
			</div>
			<div class="col l5 m5 s12">
				<div class="abot-img">
					<img src="./img/about.png" />
				</div>
			</div>
		</div>
	</div>
</section>
<section class="abou-gry">
	<div class="container">
		<div class="row">
			<div class="col l7">
				<div class="row">
					<div class="col l6 s12">
						<div class="cont-abou">
							<h5>Connect with Us!</h5>
							<p>Want to know more? Join us </p>
						</div>
					</div>
					<div class="col l6 s12">
						<div class="cont-abou">
							<ul>
								<li><a class="fb" href=""></a></li>
								<li><a class="lin" href=""></a></li>
								<li><a class="twi" href=""></a></li>
								<li><a class="you" href=""></a></li>
								<li><a class="pin" href=""></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
{this.props.loggedIn ? <Redirect to="/account"></Redirect>:null}
<Footer/>

                </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(About);