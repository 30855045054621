import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { UpdateField,fetchUser } from '../Actions/AuthActions';
import { Link } from 'react-router-dom';
import AccountNavigation from './Common/AccountNavigation';
import StaticTextBox from './Common/StaticTextBox';


const mapStateToProps = (state) => {
	return {
		profile:state.Auth.user
	}
}

const mapDispatchToProps = dispatch => ({
	UpdateField: (data) => dispatch(UpdateField(data)),
	fetchUser:()=>dispatch(fetchUser())
});
class MyAccountBookings extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",

			id:0,
			email:"",
			first_name:"",
			last_name:"",
			avatar:"",
			phone_number:"",
			updated:false

		}


		this.props.fetchUser();
	}


	static getDerivedStateFromProps(props, state){
		
        if(state.updated === false ){
			
            return {
                id:props.profile.id,
				email:props.profile.email,
				first_name:props.profile.first_name,
				last_name:props.profile.last_name,
				avatar:props.profile.avatar,
				phone_number:props.profile.phone_number,
                updated:true
            }
        }
    }


	render() {


		console.log(this.props.profile);

		return <Fragment>
			<Header  />
			<section className="body-gry setting-pg">
	<div className="container">
		<div className="row">
			<div className="col l4 m5 s12">
				<AccountNavigation/>
			</div>

			<div className="col l8 m7 s12">
				<div className="set-cont">
					<div className="hed-bed">
						<div className="bred-cmp">
							<a href="">Home</a> / <span>Settings</span>
						</div>
						<h1>Settings</h1>
					</div>
					<div className="from-div">
						<form>
							<div className="row">
								<div className="col l12">
									<h4>Personal Settings</h4>
								</div>
							</div>
							<div className="row">
								<div className="col l6 m6 s12">
									<div style={{position:'relative'}} >
											<label>Firts Name</label>
											<StaticTextBox onUpdate={this.props.UpdateField} type="text" field="first_name" value={this.state.first_name}  />
									</div>
									
									{/* <input type="text" placeholder="Full Name" name="" /> */}
								</div>
								<div className="col l6 m6 s12">
									<div style={{position:'relative'}} >
										<label>Last Name</label>
										<StaticTextBox onUpdate={this.props.UpdateField} type="text" field="last_name" value={this.state.last_name}/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col l6 m6 s12">
									<div style={{position:'relative'}} >
										<label>Phone</label>
										<StaticTextBox onUpdate={this.props.UpdateField} type="text" field="phone_number" value={this.state.phone_number}/>
									</div>
								</div>
								<div className="col l6 m6 s12">
									
								</div>
							</div>

							<div className="hr"></div>

							<div className="row">
								<div className="col l12">
									<h4>Account Settings </h4>
								</div>
							</div>
							<div className="row">
								<div className="col l6 m6 s12">
									<div style={{position:'relative'}} >
										<label>Email Address</label>
										<StaticTextBox onUpdate={this.props.UpdateField} field="email" type="email" value={this.state.email}/>
									</div>
								</div>
								<div className="col l6 m6 s12">
									<div style={{position:'relative'}} >
										<label>Password</label>
										<StaticTextBox onUpdate={this.props.UpdateField} field="password" type="password" />
									</div>
								</div>
							</div>

							{/* <div className="hr"></div>

							<div className="row">
								<div className="col l6 m6 s12">
									<h4>Newsletter Subscriptions</h4>
								</div>
								<div className="col l6 m6 s12">
									<div className="switch">
								    <label>
								      Sign in for newsletters ?
								      <input type="checkbox" />
								      <span className="lever"></span>
								    </label>
								  </div>
								</div>
							</div> */}

						</form>
					</div>

				</div>
			</div>
		</div>
	</div>
	
</section>
								<Footer />

                </Fragment>
							}
						}
						
export default compose(connect(mapStateToProps, mapDispatchToProps))(MyAccountBookings);