import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import StarRatingComponent from 'react-star-rating-component';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 470,
    backgroundColor: theme.palette.background.paper,    
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [feedback, setFeedback] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onStarClick = (nextValue, prevValue, name)=> {
    setRating(nextValue);
  }

  const onFeedbackChange = (event)=> {
    setFeedback(event.target.value);
  }

  const AddReview = () =>{
      props.AddReview({
                          supplier_id:props.data.activity.user.id,
                          activity_id:props.data.activity.id,
                          rating:rating,
                          feedback:feedback
                      });
  }

  return (
    
      
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper+' rw-pop'}>
        <div className="modal-content">
    	<a onClick={()=>{props.onClose()}} className="modal-close"><img src="/img/cs-pop.png" /></a>
    	 
    	<div className="rw-top">
	      	<div className="rw-img">
          {props.data !== null && props.data.activity.activity_images !== null ? (
						<img src={props.data.activity.activity_images[0].name} />
					):null}
		    </div>
		    <div className="rw-cont">
		      	<h4>{props.data !== null ? props.data.activity.title : ""}</h4>
		      	<p>{props.data !== null ? props.data.activity.overview.substr(0, 90) : ""}</p>
		      	{/* <div><span>Location</span>   Negombo</div>
		      	<div><span>Started / Days</span>   10 DEC '19 / 5</div> */}
		    </div>
      	</div>
      	<div className="rw-bot">
      		<h4>Rate your activity</h4>
      		<div className="ret">
          <StarRatingComponent
                          name="rate2"
                          
                          renderStarIcon={() => <span></span>}
                          starCount={5}
                          value={rating}
                          onStarClick={onStarClick.bind(this)}
                          renderStarIcon={() => <i style={{ fontSize: 48, fontStyle: 'normal' }} >★</i>}
                        />
				
			</div>
			<form>
				<textarea id="rate" maxlength="200" placeholder="Write your review...."></textarea>
				<span id="cont"></span>
				<i className="waves-effect waves-light btn">
                <input type="submit" onClick={()=>{ AddReview(); }}  value="Submit Review" name="" />
                </i>
			</form>
      	</div>
    </div>
    
          
        </div>
      </Modal>
    
  );
}